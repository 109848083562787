import { useState, useEffect} from 'react';
import { Checkbox, TextField, createFilterOptions, FormControlLabel, Typography, useMediaQuery, ListSubheader } from '@mui/material';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import StyledAutocomplete from '../CustomAutocomplete';
import { isStringEmptyOrBlank } from '../../common/utils';

// const FILTER_DATA_MAX_SIZE = 50;

const filterOptions = createFilterOptions({
  matchFrom: 'any',
  ignoreCase: true,
  // limit: FILTER_DATA_MAX_SIZE, 
});


const sortCaseInsensitive = (dataList) => {
  return dataList.sort(function (a, b) {
    return a.toLowerCase().localeCompare(b.toLowerCase());
  })
}

export default function MultipleSelectChip(props) {
  const { id, labelId, label } = props;
  const { selectedValues, setSelectedValues } = props;
  const { conditionKey, targetParam } = props;
  const { fetchData } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [tempSelectedValues, setTempSelectedValues] = useState(selectedValues || []);
  const [inputTarget, setInputTarget] = useState('');

  // Dependant filter options
  const [dynamicValues, setDynamicValues] = useState([]);
  const [displayValues, setDisplayValues] = useState([]);


  useEffect(() => {
    setTempSelectedValues(selectedValues)
  }, [selectedValues])

  const handleOnChange = (event, value) => {
    setSelectedValues(conditionKey, []);  // reset current
    if (value.length === 0) {
      return
    }
    setSelectedValues(
      conditionKey, 
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const onDelete = (value) => {
    const newData = tempSelectedValues.filter(item => item !== value)
    setTempSelectedValues(newData)
    setSelectedValues(conditionKey, newData)
  }

  const onClose = (event) => {
    event.stopPropagation();
    setSelectedValues(conditionKey, tempSelectedValues)
  };

  const handleSelectionOpen = async (event) => {
    event.stopPropagation();
    setIsLoading(true);
    try {
      await fetchData(targetParam)
        .then((data) => {
          if (data){
            const sortedData = sortCaseInsensitive(data)
            setDynamicValues(sortedData);
          }
        })
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }
  useEffect(() => {setDisplayValues(dynamicValues)}, [dynamicValues])


  useEffect(() => {
    const value = inputTarget.toUpperCase().trim()
    if (value.includes(',')) {
      const regexPattern = /[a-zA-Z0-9\s]+(?:\([a-zA-Z0-9_,\s]+\))?/g
      const searchText = value.match(regexPattern)
      if (searchText && searchText.length > 0) {
        var itemSet = new Set(searchText.map(item => item.trim()))
        const data = dynamicValues.filter((item) => itemSet.has(item.toUpperCase()))
        setDisplayValues(data)
        setTempSelectedValues(data)
        setInputTarget('')
      }
    } else if (!isStringEmptyOrBlank(value)) {
      const data = dynamicValues.filter((item) => {
        return item.toUpperCase().includes(value)
      }) 
      setDisplayValues(data || [])
    } else {
      setDisplayValues(dynamicValues)
    }
  }, [inputTarget])

  return (
    <StyledAutocomplete
      clear
      disableListWrap
      loading={isLoading}
      disableCloseOnSelect
      data-testid={labelId}
      labelId={labelId}
      id={id}
      sx={{
        width: 400,
        overflowY: 'hidden'
      }}
      multiple
      value={tempSelectedValues}
      onChange={handleOnChange}
      onClose={onClose}
      onOpen={handleSelectionOpen}
      inputValue={inputTarget}
      onInputChange={(event, newValue, reason) => {
        if (reason !== 'reset') {
          setInputTarget(newValue)
        }
      }}
      ChipProps={{
        color: 'primary',
        variant: 'outlined',
        sx: [{
          display: 'flex',
          overflow: 'hidden',
          overflowX: 'auto',
          columnGap: 0.5
        }]
      }}
      renderTags={(selected, getTagProps) =>
        selected.map((value, index) =>
          <Chip
            color='primary'
            variant='outlined'
            key={value}
            label={value}
            clickable
            onDelete={() => onDelete(value)}
            {...getTagProps({ index })}
          />
        )
      }
      limitTags={2}
      renderOption={(props, option) => {
        return (
          <Box
            data-testid='select-option-id'
            component='li'
            key={`${option}`}
            {...props}
          >
            <FormControlLabel
              value='end'
              control={<Checkbox checked={tempSelectedValues.includes(option)} />}
              labelPlacement='end'
              label={null}
            />
            {`${option}`}
          </Box>
        )
      }}     
      
      filterOptions={(options, state) => {
        if (!isStringEmptyOrBlank(state?.inputValue)) {
          return displayValues
        }
        const filtered = filterOptions(options, state);
        return filtered
      }}
      getOptionLabel={(option) => option}
      options={displayValues}
      renderInput={(params) => {
        return <TextField
          {...params}
          label={label} placeholder={label} />
      }}
    />
  );
}
