import React, { useContext } from 'react';
import MenuItem from '@mui/material/MenuItem';

import { REDRAWING_PATH } from '../../constants';
import { UserContext } from '../../containers/App/appContext';
import EditIcon from '@mui/icons-material/Edit';
import DrawTwoToneIcon from '@mui/icons-material/DrawTwoTone';

import envConfig from '../../../environments';

import { DIAGRAM_UPLOADING_STATUS_VALUES } from '../../constants';
import { getAccessToken } from '../../containers/LoginPage/auth';
import CustomizeMenus from '../CustomizeMenus';

const RedrawVersion = {
    DEFAULT: 'default',
    TU_MUNICH: 'tu_munich'
}

export default function Redraw(props) {
    const { id, status } = props.row
    const { setAlert } = props
    const { currUser, setCurrUser } = useContext(UserContext);

    const sendRedrawRequest = async (version) => {
        const params = new URLSearchParams()
        params.append('version', version)
        const accessToken = await getAccessToken(currUser, setCurrUser)
        const { id, diagramName } = props.row
        setAlert && setAlert({
            severity: 'info',
            title: 'Info',
            message: `Diagram ${diagramName} is redrawing, please wait....`
        })

        fetch(`${envConfig.BACKEND_SERVER}/${REDRAWING_PATH}/${id}?${params.toString()}`, {
            method: 'GET',
            headers: { 'auth': accessToken },
        })
        .then((res) => res.json())
        .then(data => {
            if (data && data.presigned_url) {
            setAlert && setAlert({
                severity: 'success',
                title: 'Success',
                message: `Redraw diagram ${diagramName} successfully`
            })
            window.open(data?.presigned_url, '_blank', 'noopener,noreferrer');
            } else {
            setAlert && setAlert({
                severity: 'error',
                title: 'Error',
                message: `Redraw diagram ${diagramName} got invalid response format `
            })
            }
        })
        .catch(console.error)
    }
    const handleRedrawOnClick = async (value) => {
        sendRedrawRequest(value)
    }



    const renderRedrawOptions = () => {

        return (
            [
                <MenuItem data-testid='redraw-default-version-item' key='redraw-default-version-item' onClick={() => handleRedrawOnClick(RedrawVersion.DEFAULT)}><DrawTwoToneIcon/>Default</MenuItem>,
                <MenuItem data-testid='redraw-tum-version-item' key='redraw-tum-version-item' onClick={() => handleRedrawOnClick(RedrawVersion.TU_MUNICH)}><DrawTwoToneIcon/>TU Munich</MenuItem>
            ]
        )
    }

    if (![DIAGRAM_UPLOADING_STATUS_VALUES.SUCCESS].includes(status)) return
    
    return (
        <div>
            <CustomizeMenus
                dataTestId='redraw-menu-id'
                id={`redraw-diagram-${id}-menu`} 
                label=''
                icon={<EditIcon/>}
                renderOptions={renderRedrawOptions}
            />
        </div>
    )
}