import { Box } from '@mui/system';

import DiffCheck from '../DiffCheck';

export default function ComponentDetails(props) {
    const { leftSide, rightSide } = props
    const { leftName, rightName } = props
    const { shouldDisplayRightSide } = props
    const { tecaId } = props
    const { checkedComponentNames } = props
    const { selectedComparateeId } = props
    return (
        <Box >
            <DiffCheck
                leftName={leftName}
                rightName={rightName}
                oldText={leftSide}
                newText={rightSide}
                shouldDisplayRightSide={shouldDisplayRightSide}
                tecaId={tecaId}
                checkedComponentNames={checkedComponentNames}
                selectedComparateeId={selectedComparateeId}
            />
        </Box>
    )
}
