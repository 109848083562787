import React, { useContext, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { IconButton } from '@mui/material';
import { DIAGRAM_DETAIL_PATH } from '../../constants';
import { UserContext } from '../../containers/App/appContext';

import Box from '@mui/material/Box';
import DownloadIcon from '@mui/icons-material/Download';
import envConfig from '../../../environments';

import { DIAGRAM_UPLOADING_STATUS_VALUES } from '../../constants';
import StatusButtonWithModal from '../StatusButtonWithModal';
import { getAccessToken } from '../../containers/LoginPage/auth';
import Redraw from '../Redraw';

function StatusTag(props) {
  const { value, shouldDisplayModifyButton, row } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const { setAlert } = props;

  let bgColor = '#bbbbbb';
  let message = 'Diagram is not yet processed';
  let openModel = false;

  if (value === DIAGRAM_UPLOADING_STATUS_VALUES.SUCCESS) {
    bgColor = '#30CB83';
    message = 'Diagram is uploaded and ready to use'
  } else if (value === DIAGRAM_UPLOADING_STATUS_VALUES.SUCCESS_WITH_ERROR) {
    bgColor = '#C0DC91';
    // TODO: add information for missing components/pins
    message = 'TODO: add information for missing components/pins';
    openModel = true;
  } else if (value === DIAGRAM_UPLOADING_STATUS_VALUES.FAILED) {
    bgColor = '#D9534F';
    message = props.row.error;
  } else if (value === DIAGRAM_UPLOADING_STATUS_VALUES.NOT_YET_EXTRACTED) {
    bgColor = '#bbbbbb';
    message = 'Diagram is not yet uploaded';
  } else if (value === DIAGRAM_UPLOADING_STATUS_VALUES.PROCESSING) {
    bgColor = '#9B59B6';
  } else if (value === DIAGRAM_UPLOADING_STATUS_VALUES.PENDING) {
    bgColor = '#5BC0DE';
  } else if (value === DIAGRAM_UPLOADING_STATUS_VALUES.MANUALLY_EDITED) {
    bgColor = '#1C794E';
    openModel = true;
  }

  const handleStatusClick = (event) => {
    event.stopPropagation()
    setModalOpen(shouldDisplayModifyButton && openModel);
  }

  return (
    <StatusButtonWithModal
      diagramId={props?.id}
      imageUri={row?.imageUri}
      bgColor={bgColor}
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      handleStatusClick={handleStatusClick}
      value={value}
      setAlert={setAlert}
    />
  )
}

function DownloadTag(props) {
  const value = props.row.status;
  const id = props.row.id;
  const diagramName = props.row.diagramName;
  const { currUser, setCurrUser } = useContext(UserContext);

  const params = new URLSearchParams({
    diagram_id_list: id + '$' + diagramName,
    output_type: 'excel'
  });

  const handleDownloadClick = async (event) => {
    event.stopPropagation()
    const accessToken = await getAccessToken(currUser, setCurrUser)
    fetch(`${envConfig.BACKEND_SERVER}/${DIAGRAM_DETAIL_PATH}?${params.toString()}`, {
      method: 'GET',
      headers: { 'auth': accessToken },
    })
      .then((res) => res.json())
      .then(data => {
        if (data && data.body) {
          const base64String = data.body;
          const binaryData = atob(base64String);
          const uint8Array = new Uint8Array(binaryData.length);
          for (let i = 0; i < binaryData.length; i++) {
            uint8Array[i] = binaryData.charCodeAt(i);
          }
          const blob = new Blob([uint8Array], { type: 'application/zip' });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = diagramName + '.zip';
          link.click();
        } else {
          console.error('Invalid response format');
        }
      })
      .catch(console.error);
  };

  if (value === DIAGRAM_UPLOADING_STATUS_VALUES.SUCCESS) {
    return (
      <IconButton onClick={handleDownloadClick} >
        <DownloadIcon />
      </IconButton >
    );
  }
  return null;
}

export default function DiagramDataGrid(props) {
  const { rows, rowCountState, handleRowClick } = props;
  const { isLoading } = props;
  const { paginationModel, setPaginationModel } = props;
  const { customColumns } = props;  // Used for testing
  const { setAlert } = props;

  let columns;
  if (customColumns != null) {
    columns = customColumns;
  } else {
    columns = [
      {
        field: 'id',
        headerName: 'ID',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
      },
      {
        field: 'diagramName',
        headerName: 'Diagram Name',
        headerAlign: 'center',
        align: 'center',
        minWidth: 150,
      },
      {
        field: 'diagramType',
        headerName: 'Diagram Type',
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'taTypeIDList',
        headerName: 'TA Type ID',
        headerAlign: 'center',
        align: 'center',
        minWidth: 200,
      },  
      {
        field: 'taRangeIDList',
        headerName: 'TA Range ID',
        headerAlign: 'center',
        align: 'center',
        minWidth: 200,
      },
      {
        field: 'taRangeNameList',
        headerName: 'TA Range Name',
        headerAlign: 'center',
        align: 'center',
        minWidth: 200,
      },
      {
        field: 'group',
        headerName: 'Group',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
      },
      {
        field: 'model',
        headerName: 'Model',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
      },
      {
        field: 'brand',
        headerName: 'Brand',
        headerAlign: 'center',
        align: 'center',
        flex: 1,
      },
      {
        field: 'domainValue',
        headerName: 'Domain Value',
        headerAlign: 'center',
        align: 'center',
        minWidth: 150,
      },
      {
        field: 'functionValue',
        headerName: 'Function Value',
        headerAlign: 'center',
        align: 'center',
        minWidth: 200,
      },
      {
        field: 'engineCode',
        headerName: 'Engine Code',
        headerAlign: 'center',
        align: 'center',
        minWidth: 170,
      },
      {
        field: 'body',
        headerName: 'Body',
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'brakeSystem',
        headerName: 'Brake System',
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'centralLocking',
        headerName: 'Central locking',
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'countryVersion',
        headerName: 'Country version',
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'drive',
        headerName: 'Drive',
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'exhaustAftertreatment',
        headerName: 'Exhaust aftertreatment',
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'factory',
        headerName: 'Factory',
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'adas',
        headerName: 'Advanced Driver Assistance Systems',
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'fuelSystem',
        headerName: 'Fuel system',
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'gearbox',
        headerName: 'Gearbox',
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'hac',
        headerName: 'Heating/Air conditioning',
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'rnp',
        headerName: 'Radio/Navigation/Phone',
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'steering',
        headerName: 'Steering',
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'windowLifter',
        headerName: 'Window Lifter',
        headerAlign: 'center',
        align: 'center',
      },
      {
        field: 'others',
        headerName: 'Others',
        headerAlign: 'center',
        align: 'center',
        minWidth: 150,
      },
      {
        field: 'status',
        headerName: 'Status',
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
          return (
            <StatusTag
              setAlert={setAlert}
              shouldDisplayModifyButton={true}
              {...params}
              />
            )
          },
        minWidth: 200,
      },
      {
        field: 'downloadStatus',
        headerName: 'Excel',  // use shorter name to save space
        headerAlign: 'center',
        align: 'center',
        renderCell: DownloadTag,
        width: 50,
      },
      {
        field: 'redraw',
        headerName: 'Redraw',
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => {
          return (
            <Redraw data-testid='redraw-col-id' setAlert={setAlert} {...params}/>
          )
        },
        width: 75,
      },
    ];
  }

  const onPageChange = (model) => {
    setPaginationModel({ ...paginationModel, page: model.page })
  }

  return (
    <Box sx={{ width: '100%', overflow: 'auto' }}>
      <DataGrid
        disableVirtualization // this line support unit test for loading all document
        data-testid='diagram-table'
        autoPageSize
        autoHeight
        rows={rows}
        columns={columns}
        rowCount={rowCountState}
        loading={isLoading}
        pageSizeOptions={[10]}
        paginationModel={paginationModel}
        paginationMode='server'
        onPaginationModelChange={onPageChange}
        onRowClick={handleRowClick}
        disableColumnMenu
      />
    </Box>
  );
}
