export default {
    BACKEND_SERVER: 'https://35d1f0785b.execute-api.eu-central-1.amazonaws.com/dev',
    REGION: 'eu-central-1',
    USER_POOL_ID: 'eu-central-1_5tAiuYR6L',
    USER_POOL_APP_CLIENT_ID: '1h7efs5ps4vr5207dkq5oltkv1',
    IDENTITY_POOL_ID: 'eu-central-1:a38f494c-7ce2-4cbb-9de0-cd3ba148d233',
    WIRING_DIAGRAM_BUCKET: 'ta-wiring-diagram-rdp-dev',
    WIRING_DIAGRAM_BULK_UPLOAD_BUCKET: 'ta-wiring-diagram-rdp-dev',
    JWT_EXPIRE_IN: '1h',
    USER_POOL_DOMAIN: 'wiring-diagram-user-pool-dev.auth.eu-central-1.amazoncognito.com',
    APP_DIRECT_URL: 'http://localhost:3000/',
    AZURE_REDIRECT_URL: 'http://localhost:3000/user-management/blank.html',
    IDENTITY_PROVIDER: 'TecAllianceConnectID',
    AZURE_APP_ID: 'b50840c8-8278-44b8-b161-973723e7df22',
    TENANT_ID: '91262959-85ec-4b1c-b7d3-7c3aac19c595',
    USER_POOL_GROUPS: {
        ADMIN: 'admins_dev',
        USER: 'users_dev'
    },
    AZURE_GROUP_ID: [
        '76983e0a-126f-44b3-97d2-9478a08ef8ac',
        'c45e1c91-7a2e-4148-81ec-951f0cc21a95'
    ],
    USE_SSO: true,
    SHOW_API_DOCUMENTATION: false,
}
