import { Component } from 'react';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { 
    Alert, 
    Box, 
    Chip, 
    DialogActions, 
    FormControlLabel, 
    IconButton, 
    Input, 
    InputAdornment, 
    InputLabel, 
    Radio, 
    RadioGroup 
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { READ_USER_API } from '../../constants';
import { getErrorMessages } from './userManagementException';
import { 
    addUserToGroup, 
    adminCreateUser, 
    getUsersInCognitoByUsername, 
    linkProviderForUser, 
    USER_POOL_GROUPS 
} from './user';
import { isObjectEmptyOrNull, isStringEmptyOrBlank } from '../../common/utils';

export default class AddUserPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputEmail: '',
            user: null,
            errMsg: '',
            successMsg: '',
            newUserGroup: USER_POOL_GROUPS?.USER || 'user'
        }
    }

    componentDidMount() {
        this.resetStates()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.open !== this.props.open) {
            this.resetStates()
        }
    }

    resetStates = () => {
        this.setState({
            inputEmail: '',
            user: null,
            errMsg: '',
            successMsg: ''
        })
    }

    handleClose = () => {
        this.props.onClose();
    };

    onSearchEmail = () => {
        this.getUserByEmail()
    }

    getUserByEmail = async () => {
        const emailSuffix = '@tecalliance.net'
        const fullEmail = this.state.inputEmail.toLowerCase().match('@') ?
            this.state.inputEmail :
            `${this.state.inputEmail}`.concat(emailSuffix)
        await this.props.callGraphAPI(`${READ_USER_API}/${fullEmail}`)
            .then(response => {
                const error = response.error
                if (error) {
                    const errMsg = getErrorMessages(error, fullEmail)
                    this.setState({
                        errMsg: errMsg || '',
                        user: null
                    })
                } else {
                    this.setState({
                        errMsg: '',
                        user: response
                    })
                }
            })
            .catch(err => {
                this.setState({
                    errMsg: err
                })
            })
    }

    getCreatedUsername = async (username) => {
        const user = await getUsersInCognitoByUsername(username);
        if (!isStringEmptyOrBlank(user?.Username)) {
            return user?.Username;
        } else {
            return await adminCreateUser(this.state.user, username)
                .then((response) => {
                    return response?.User;
                })
                .then(async (response) => {
                    if (response) {
                        return await linkProviderForUser(this.state.user)
                            .then(response => response.$response)
                            .then(async (response) => {
                                if (response && [200, 204].includes(response.httpResponse.statusCode)) {
                                    return username;
                                } else {
                                    throw new Error(response?.error?.message);
                                }
                            }).catch(err => console.log(err))
                    } else {
                        throw new Error(`Can't create user with username: ${username}`)
                    }
                });
        }
    }


    onCreateNewUserHandle = async () => {
        let errMsg = ''
        let successMsg = ''

        try {
            if (this.state.user && this.state.user?.id) {
                const mail = this.state.user?.mail || ''
                const username = mail?.split('@')[0].toLowerCase() || ''
                const createdUsername = await this.getCreatedUsername(username)         
                if (!isStringEmptyOrBlank(createdUsername)) {
                    await addUserToGroup(createdUsername, this.state.newUserGroup)
                        .then((response) => {
                            if (response?.$response?.httpResponse?.statusCode === 200 ||
                                response?.status === 204) {
                            successMsg = `User created and added to the ${this.state.newUserGroup} group`
                        }})

                } else {
                    throw new Error("Created user is null");

                }
            } else {
                errMsg = `Can't find user ${this.state.user?.mail}`
            }
        } catch(ex) {
            console.log(ex)
            errMsg = `There is an error while creating a new user: ${ex?.code}`
        } finally {
            console.log(errMsg, successMsg)
            this.setState({
                errMsg: errMsg,
                successMsg: successMsg
            })
        }
    }

    handleRoleChange = (event) => {
        this.setState({
            newUserGroup: event.target.value
        })
    }

    render() {
        return (
            <Dialog onClose={this.handleClose} open={this.props.open}>
                <Box
                    sx={{
                        p: 2,
                        minWidth: 250,
                        gap: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    {
                        !isStringEmptyOrBlank(this.state.errMsg) &&
                        <Alert severity='error'>{this.state.errMsg}</Alert>
                    }
                    {
                        !isStringEmptyOrBlank(this.state.successMsg) &&
                        <Alert severity='success'>{this.state.successMsg}</Alert>
                    }
                    <DialogTitle alignContent='center'>Add User</DialogTitle>

                    <Box
                        sx={{
                            '& .MuiChip-root': { m: 1, width: '20ch' },
                            gap: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignItems: 'center'

                        }}
                    >
                        <Box sx={{
                                display: 'flex'
                            }}
                        >
                            <FormControl>
                                <InputLabel htmlFor='invite-user-with-icon'>Email</InputLabel>
                                <Input id='invite-user-with-icon'
                                    sx={{
                                        minWidth: 200
                                    }}
                                    endAdornment={
                                        <InputAdornment position='end'>
                                            <IconButton
                                                size='small'
                                                sx={{
                                                    bgcolor: 'inherit',
                                                    borderRadius: 5,
                                                    fontSize: 'inherit',
                                                    alignItems: 'center',
                                                    '&:hover': { backgroundColor: 'inherit' }

                                                }}
                                                onClick={this.onSearchEmail}
                                            >
                                                <SearchIcon color='info' />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    onChange={(e) => this.setState({
                                        inputEmail: e.target.value
                                    })}
                                    components={Chip}

                                    />
                            </FormControl>
                            <FormControl>
                                <RadioGroup
                                    row
                                    sx={{
                                        pt:2,
                                        pl:2
                                    }}
                                    onChange={this.handleRoleChange}
                                    defaultValue={this.state.newUserGroup}
                                >
                                    <FormControlLabel
                                        value={USER_POOL_GROUPS?.ADMIN}
                                        control={<Radio />}
                                        label='Admin'
                                    />
                                    <FormControlLabel
                                        value={USER_POOL_GROUPS?.USER}
                                        control={<Radio />}
                                        label='User'
                                    />

                                </RadioGroup>
                            </FormControl>
                        </Box>
                        {
                            this.state.user ?
                                <Chip
                                    label={`${this.state.user?.displayName}`}
                                    color='info'
                                    variant='outlined'

                                />
                                : null
                        }

                    </Box>
                    <DialogActions>
                        <Button
                            onClick={this.handleClose}
                            sx={{
                                backgroundColor: '#aaaaaa',
                                '&:hover': { backgroundColor: '#8c8b8b' }
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={this.state.user ? false : true}
                            sx={{
                                '&:disabled': {
                                    color: 'white',
                                    backgroundColor: '#aaaaaa'
                                }
                            }}
                            onClick={this.onCreateNewUserHandle}
                        >
                            Invite
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        );
    }
}
