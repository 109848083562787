import React, { useState } from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {
    Alert,
    Box,
    DialogActions,
    FormControl,
    FormControlLabel,
    Paper,
    Radio,
    RadioGroup,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { USER_POOL_GROUPS, addUserToGroup, removeUserFromGroup } from './user';

export default class EditUserPopup extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            inputEmail: '',
            user: null,
            errMsg: '',
            successMsg: '',
            infoMsg: '',
            users: [],
            changeUserList: []
        }
    }

    componentDidMount() {
        this.resetState()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.open !== this.props.open) {
            this.resetState()
        }
    }

    resetState = () => {
        this.setState({
            inputEmail: '',
            user: null,
            errMsg: '',
            successMsg: '',
            infoMsg: '',
            users: this.props.selected || []
        })
    }

    handleClose = () => {
        this.props.onClose();
    };

    addUserIntoChangeList = (updatedUser) => {
        const newChangeUserList = this.state.changeUserList
            .filter(user => user.id !== updatedUser.id)
        this.setState({
            changeUserList: [...newChangeUserList, updatedUser]
        })
    }
    resetMsgAlert = () => {
        this.setState({
            errMsg: '',
            successMsg: '',
            infoMsg: '',
        })
    }

    onUpdateUserRole = async () => {
        this.resetMsgAlert()
        const orignalUserSet = new Set(this.state.users || [])
        const handleList = this.state.changeUserList.filter(user => !orignalUserSet.has(user))
        const notChange = orignalUserSet.size - handleList.length
        const allPromises = handleList.map(async user => {
            return await addUserToGroup(user?.id, user?.newRole)
            .then(async(response) => {
                if (response) {
                    return await removeUserFromGroup(user?.id, user?.oldRole)
                        .then((response) => {
                            return response
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                }
                return response
            })
                    
                
        })
        let errors = []
        try {
            let sucessCount = 0
            const responses = await Promise.all(allPromises)
            if (responses) {
                for (let response of responses) {
                    if (response.$response?.error ||
                        response?.error?.message) {
                        errors.push((
                            response.$response?.error?.message ||
                            response?.error?.message
                        ))
                    }
                    if (response?.$response?.httpResponse?.statusCode === 200 ||
                        response?.status === 204) {
                        sucessCount++
                    }
                }
                if (errors.length !== 0) {
                    this.setState({
                        errMsg: 'Roles edited failed. Please check in log'
                    })
                    if (sucessCount !== 0) {
                        this.setState({
                            successMsg: `Successfully edited ${sucessCount} users`
                        })
                    }
                    console.log(errors.join('\n'))
                } else {
                    this.setState({
                        successMsg: `Successfully edited ${sucessCount} users`
                    })
                }
            }
        } catch (err) {
            this.setState({
                errMsg: 'Roles edited failed. Please check in log'
            })
            console.log('Catch: ' + errors.join('\n'))
        }
        if (notChange > 0) {
            this.setState({
                infoMsg: `${notChange} user(s) not changed`
            })
        }
    }

    render() {
        return (
            <Dialog onClose={this.handleClose} open={this.props.open}>
                <Box
                    sx={{
                        p: 2,
                        minWidth: 250,
                        gap: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    {
                        this.state.errMsg !== '' &&
                        <Alert severity='error'>{this.state.errMsg}</Alert>
                    }
                    {
                        this.state.successMsg !== '' &&
                        <Alert severity='success'>{this.state.successMsg}</Alert>
                    }
                    {
                        this.state.infoMsg !== '' &&
                        <Alert severity='info'>{this.state.infoMsg}</Alert>
                    }
                    <DialogTitle alignContent='center'>Edit User</DialogTitle>

                    <Box
                        sx={{
                            '& .MuiChip-root': { m: 1, width: '25ch' },
                            gap: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignItems: 'center'

                        }}
                    >

                        <TableContainer component={Paper}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Username</TableCell>
                                    <TableCell>Role</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    this.state.users ? this.state.users.map(row => {
                                        return (
                                            <TableRow key={row?.id}>
                                                <TableCell>{row?.id}</TableCell>
                                                <TableCell>
                                                    <RoleComponent
                                                        user={row}
                                                        addUserIntoChangeList={this.addUserIntoChangeList}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }) : null
                                }
                            </TableBody>
                        </TableContainer>
                    </Box>
                    <DialogActions>
                        <Button
                            onClick={this.handleClose}
                            sx={{
                                backgroundColor: '#aaaaaa',
                                '&:hover': { backgroundColor: '#8c8b8b' }
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={this.state.changeUserList.length === 0}
                            onClick={this.onUpdateUserRole}
                            sx={{
                                '&:disabled': {
                                    color: 'white',
                                    backgroundColor: '#aaaaaa'
                                }
                            }}
                        >
                            Submit
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        );
    }
}

export const RoleComponent = ({ user, addUserIntoChangeList }) => {
    const [changeUser, setChangeUser] = useState(user)
    const isAdmin = changeUser?.isAdmin
    let newUser = {
        ...changeUser
    }
    const handleChange = (event) => {
        switch (event.target.value) {
            case 'admin':
                newUser = {
                    ...newUser,
                    newRole: USER_POOL_GROUPS?.ADMIN,
                    oldRole: USER_POOL_GROUPS?.USER,
                    isAdmin: true,
                    isUser: true,

                }
                setChangeUser(newUser)
                break
            case 'user':
            default:
                newUser = {
                    ...newUser,
                    newRole: USER_POOL_GROUPS?.USER,
                    oldRole: USER_POOL_GROUPS?.ADMIN,
                    isAdmin: false,
                    isUser: true,

                }
                setChangeUser(newUser)
                break
        }
        addUserIntoChangeList(newUser)
    }
    return (
        <FormControl>
            <RadioGroup
                row
                value={isAdmin ? 'admin' : 'user'}
                onChange={handleChange}
            >
                <FormControlLabel
                    value='admin'
                    control={<Radio />}
                    label='Admin'
                />
                <FormControlLabel
                    value='user'
                    control={<Radio />}
                    label='User'
                />

            </RadioGroup>
        </FormControl>
    )
}