export default {
    BACKEND_SERVER: 'https://hlajk5adwa.execute-api.eu-central-1.amazonaws.com/stg',
    REGION: 'eu-central-1',
    USER_POOL_ID: 'eu-central-1_Tuasr4Tdq',
    USER_POOL_APP_CLIENT_ID: 'qreqn2v8d7bu81bv8bnn374d',
    IDENTITY_POOL_ID: 'eu-central-1:8cf33e5c-682f-4732-970f-8b711f4b6954',
    WIRING_DIAGRAM_BUCKET: 'ta-wiring-diagram-rdp-stg',
    WIRING_DIAGRAM_BULK_UPLOAD_BUCKET: 'ta-wiring-diagram-rdp-bulk-upload-stg',
    JWT_EXPIRE_IN: '1h',
    USER_POOL_DOMAIN: 'wiring-diagram-user-pool-stg.auth.eu-central-1.amazoncognito.com',
    APP_DIRECT_URL: 'https://www.wiring-diagram-stg.tecalliance.cloud/',
    AZURE_REDIRECT_URL: 'https://www.wiring-diagram-stg.tecalliance.cloud/user-management/blank.html',
    IDENTITY_PROVIDER: 'TecAllianceConnectID',
    AZURE_APP_ID: '3b74e2dc-ae59-490c-bc70-5930bddaaa4b',
    TENANT_ID: '91262959-85ec-4b1c-b7d3-7c3aac19c595',
    USER_POOL_GROUPS: {
        ADMIN: 'admins_stg',
        USER: 'users_stg'
    },
    AZURE_GROUP_ID: [
        '76983e0a-126f-44b3-97d2-9478a08ef8ac',
        'c45e1c91-7a2e-4148-81ec-951f0cc21a95'
    ],
    USE_SSO: true,
    SHOW_API_DOCUMENTATION: false,
}
