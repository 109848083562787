import {
    Autocomplete,
    CircularProgress,
    autocompleteClasses,
    Box,
} from '@mui/material';

import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useRef, useEffect, createContext, forwardRef, useContext } from 'react';
import { VariableSizeList } from 'react-window';
import PropTypes from 'prop-types';

const MIN_HEIGHT = 50;
const LISTBOX_PADDING = 15;

const OuterElementContext = createContext({})
const OuterElementType = forwardRef((props, ref) => {
    const outerProps = useContext(OuterElementContext)
    return <div ref={ref} {...props} {...outerProps}/>
})

// It ensures that the virtualized list resets its cached positions and sizes 
// whenever the underlying data (like itemCount) changes.
function useResetCache(data) {
    const ref = useRef(null)
    useEffect(() => {
      if (ref.current != null) {
        ref.current.resetAfterIndex(0, true)
      }
    }, [data]);
  
    return ref;
}

const VirtualizedListbox = forwardRef(function VirtualizedListbox(props, ref) {
    const { children, ...other} = props;
    const itemCount = children.length;
    const itemData = [];
  
  
    children.forEach(item => {
      itemData.push(item);
      itemData.push(...(item?.children || []))
    });

    const itemSize = MIN_HEIGHT
    const getChildSize = (child) => {
      if (child?.hasOwnProperty('group')) {
        return 48;
      }
  
      return itemSize;
    };

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize;
      }
      return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };
  
    const gridRef = useResetCache(itemCount)
  
    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight() + 2 * LISTBOX_PADDING}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType='ul'
            itemSize={(index) => getChildSize(itemData[index])}
            overscanCount={5}
            itemCount={itemCount}
          >
            {({ index, style }) => (
                <div style={style} key={index}>
                    {children[index]}
                </div>
            )}
  
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    )
  
  })

VirtualizedListbox.propTypes = {
    children: PropTypes.node,
};

const CustomAutocomplete = (props) => {
    // Autocomplete doesn't provide onClick callback.
    // The only and simple way is wrap the Autocomplete with Box component.
    const { onClick } = props;
    return (
        <Box onClick={onClick}>
            <Autocomplete
                ListboxComponent={VirtualizedListbox}
                popupIcon={
                    props?.loading ?
                        <CircularProgress
                            data-testid='loading-indicator-id'
                            size='1.5rem' />
                        : <ArrowDropDownIcon />
                }
                {...props}
            />
        </Box>
    )
}

const StyledAutocomplete = styled(CustomAutocomplete)(({ theme }) => ({
    [`& .${autocompleteClasses.clearIndicator}`]: {
        backgroundColor: 'white',
        color: '#2286F7'
    },
    [`& .${autocompleteClasses.popupIndicator}`]: {
        backgroundColor: 'white',
        color: '#2286F7'
    },
    [`& .${autocompleteClasses.inputRoot}`]: {
        minHeight: MIN_HEIGHT,
        width: '100%',
        display: 'flex'
    },
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: 'border-box',
      },
}));

export default StyledAutocomplete;
