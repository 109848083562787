import { CognitoIdentityServiceProvider } from 'aws-sdk';
import envConfig from '../../../environments';
import { getCredentials } from '../LoginPage/auth';
import { isStringEmptyOrBlank } from '../../common/utils';


export const USER_POOL_GROUPS = envConfig?.USER_POOL_GROUPS || {}
export const USER_POOL_GROUP_NAMES = Object.values(USER_POOL_GROUPS) || []

const initiateCognitoClient = async () => {
    const credentials = await getCredentials()
    return new CognitoIdentityServiceProvider({
        region: envConfig.REGION,
        credentials: credentials,
    })
}

export const getUsersWithGroupsInUserPool = async () => {
    try {
        const groupPromises = USER_POOL_GROUP_NAMES.map(async groupName => {
            return await listUsersInGroupInUserPool(groupName)
        })
        const groupsResults = await Promise.all(groupPromises)
        if (!groupPromises) return []
        const result = mapUserWithGroups(groupsResults)
        return result
    } catch (err) {
        console.log(err)
    }
    return []
}

const mapUserWithGroups = (groupsResults) => {
    let usersByGroup = {}
    for (let item of groupsResults) {
        usersByGroup = {
            ...usersByGroup,
            [item?.group]: new Set(item?.users.map(user => user.id))
        }
    }
    let dataSet = new Set()
    for (let item of groupsResults) {
        for (let users of item?.users) {
            dataSet.add(users)
        }
    }
    let result = {}
    for (let user of dataSet) {
        for (let groupName of Object.keys(usersByGroup)) {
            if (usersByGroup?.[groupName].has(user?.id)) {
                const currUser = result?.[user?.id] || user
                const currGroups = new Set([...(currUser?.groups || []), groupName])
                result = {
                    ...result,
                    [user?.id]: {
                        ...currUser,
                        groups: Array.from(currGroups),
                        isUser: true,
                        isAdmin: currUser?.isAdmin || USER_POOL_GROUPS?.ADMIN === groupName,
                    }
                }
            }
        }
    }

    return Object.values(result)

}


export const getDisplayGroupName = (groupName) => {
    switch (groupName) {
        case USER_POOL_GROUPS?.ADMIN:
            return 'Admin';
        case USER_POOL_GROUPS?.USER:
            return 'User';
        default:
            return groupName
    }

}

const listUsersInGroupInUserPool = async (groupName) => {
    var params = {
        UserPoolId: envConfig.USER_POOL_ID,
        GroupName: groupName
    }
    const cognito = await initiateCognitoClient()
    return await cognito.listUsersInGroup(params)
        .promise()
        .then((response) => {
            if (response) {
                const data = response?.Users.map(
                    (user) => {
                        let attrs = {}
                        for (const attr of user?.Attributes || []) {
                            attrs = {
                                ...attrs,
                                [attr?.Name]: `${attr?.Value}`
                            }
                        }
                        const displayName = !isStringEmptyOrBlank(attrs?.given_name) && !isStringEmptyOrBlank(attrs?.family_name) ? `${attrs?.given_name}, ${attrs.family_name}` : ''
                        return {
                            'id': user?.Username,
                            'email': attrs?.email,
                            'userStatus': user?.UserStatus,
                            'displayName': displayName
                        }
                    }

                )
                return {
                    group: groupName,
                    users: data
                }
            }
            return []

        }).catch((err) => {
            console.log(err)
        })
}

export const deleteUsersInUserPool = async (username) => {
    var params = {
        UserPoolId: envConfig.USER_POOL_ID,
        Username: username
    }
    const cognito = await initiateCognitoClient()
    return await cognito.adminDeleteUser(params).promise()
}

export const addUserToGroup = async (username, groupName) => {
    var params = {
        UserPoolId: envConfig.USER_POOL_ID,
        Username: username,
        GroupName: groupName
    }
    const cognito = await initiateCognitoClient()
    return await cognito.adminAddUserToGroup(params).promise()
}

export const adminCreateUser = async (user, username) => {
    const mail = user?.mail || ''
    const params = {
        UserPoolId: envConfig.USER_POOL_ID,
        Username: username,
        UserAttributes: [
          { Name: "email", Value: mail },
          { Name: "given_name", Value: user?.givenName },
          { Name: "family_name", Value: user?.surname },
          { Name: "name", Value: user?.userPrincipalName},
          { Name: "custom:group", Value: `[${envConfig.AZURE_GROUP_ID.join(", ")}]` }, // Optional, if needed
          { Name: "email_verified", Value: "true" },
        ],
        MessageAction: "SUPPRESS", // Prevents Cognito from sending the welcome email
      };

    const cognito = await initiateCognitoClient()
    return await cognito.adminCreateUser(params).promise()

}

export const linkProviderForUser = async (user) => {
    const destinationUser = {
        ProviderName: 'Cognito',
        ProviderAttributeValue: user?.mail
    }
    const sourceUser = {
        ProviderName: envConfig.IDENTITY_PROVIDER,
        ProviderAttributeValue: user?.mail,
        ProviderAttributeName: 'Cognito_Subject',
    }
    var params = {
        UserPoolId: envConfig.USER_POOL_ID,
        SourceUser: sourceUser,
        DestinationUser: destinationUser,
    }
    const cognito = await initiateCognitoClient()
    return await cognito.adminLinkProviderForUser(params).promise()
}

export const getUsersInCognitoByUsername = async (username) => {
    const params = {
        UserPoolId: envConfig.USER_POOL_ID,
        Filter: `username="${username}"`
    };
    const cognito = await initiateCognitoClient()
    return await cognito.listUsers(params)
        .promise()
        .then(response => {
            return response?.Users || []
        }).
        then(users => {
            if (users) {
                for(let user of users) {
                    if (user?.Username.toLowerCase() === username) {
                        return user
                    }
                }
            }
            return {}
        })
}
export const removeUserFromGroup = async (username, groupName) => {
    var params = {
        UserPoolId: envConfig.USER_POOL_ID,
        Username: username,
        GroupName: groupName
    }
    const cognito = await initiateCognitoClient()
    return await cognito.adminRemoveUserFromGroup(params).promise()
}

export const requestAccessToken = async (instance, account) => {
    const loginRequest = {
        scope: [
            'User.ReadBasic.All',
            'User.ReadWrite',
            'profile',
            'email'
        ],
    }
    return await instance.acquireTokenSilent({
        ...loginRequest,
        account: account
    }).then(response => {
        return response?.accessToken
    }).catch(err => {
        console.log(err)
    })

}


export const fetchGraphData = async (endpoint, accessToken, method = 'GET', body = null) => {
    const headers = new Headers()
    var bearer = `Bearer ${accessToken}`
    headers.append("Authorization", bearer)
    headers.append("Content-Type", 'application/json')
    var options = {
        method: method,
        headers: headers,
        body: body ? JSON.stringify(body) : null
    }
    return await fetch(endpoint, options)
        .then(async (response) => {
            if (response && response.status === 204) {
                return response
            }
            if (response) {
                return await response.json()
            }
            return response
        })
        .then((data) => {
            return data
        })
        .catch(err => {
            console.log(err)
            throw err
        })
}

export const isAdminUser = (currUser) => {
    return currUser !== null && currUser?.groups?.includes(USER_POOL_GROUPS.ADMIN)
}