import React, { useEffect, useState } from 'react';
import { Amplify } from 'aws-amplify';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { Hub } from '@aws-amplify/core'
import { AppContext, UserContext } from './appContext';
import { getCurrentSession, updateCurrentUser } from '../LoginPage/auth';
import LoginPage from '../LoginPage';
import AppBar from '../../components/AppBar';
import TabsRouter from '../../components/TabsRouter';
import envConfig from '../../../environments';
import { isObjectEmptyOrNull } from '../../common/utils'

import 'semantic-ui-css/semantic.min.css';


Amplify.configure({
  Auth: {
      identityPoolId: envConfig?.IDENTITY_POOL_ID || '',
      region: envConfig.REGION,
      userPoolId: envConfig.USER_POOL_ID,
      userPoolWebClientId: envConfig.USER_POOL_APP_CLIENT_ID,
      oauth: {
          domain: envConfig.USER_POOL_DOMAIN,
          scope: ['email', 'openid'],
          redirectSignIn: envConfig.APP_DIRECT_URL,
          redirectSignOut: envConfig.APP_DIRECT_URL,
          responseType: 'code'
      }
  }
})

const msalConfig = {
  auth: {
    clientId: envConfig?.AZURE_APP_ID,
    authority: `https://login.microsoftonline.com/${envConfig?.TENANT_ID}`,
    redirectUri: envConfig.AZURE_REDIRECT_URL
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
  },
  system: {
    //https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/6169
    allowNativeBroker: false, // Disables WAM Broker 
    allowRedirectInIframe: true,
    windowHashTimeout: 60000,
    iframeHashTimeout: 10000,
  },
  telemetry: {
    application: {
      appName: "Wiring Diagram Tool",
      appVersion: "1.0.0",
    },
  },
}
const msalInstance = (envConfig?.USE_SSO || false) ? new PublicClientApplication(msalConfig) : null
if (msalInstance) {
  await msalInstance.initialize()
}

const App = () => {
  const [token, setToken] = useState(null)
  const [data, setData] = useState({})
  const [currUser, setCurrUser] = useState(null)

  useEffect(() => {
    Hub.listen('auth', ({
      payload: { event, data }
    }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          setToken('granting')
          getUser()
          break;
        case 'signOut':
          setToken(null)
          setCurrUser({})
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          // TODO: Should have action for failure
          console.log('Sign in failure', data)
          break;
        default:
          break;
      }
    })
  })

  useEffect(() => {
    // keep session alive
    getUser()
  }, [])
  
  const getUser = async () => {
    await getCurrentSession()
        .then(userToken => {
            if (userToken && userToken !== null) {
              setToken(userToken.getIdToken())
              updateCurrentUser(userToken, currUser, setCurrUser)
            }
          })
        .catch((err) => console.log(err))
  }

  const renderLogInPage = () => {
    return (
      <LoginPage callback={getUser} />
    )
  }

  const renderApp = () => {
    return (
      <div className='ui fluid container'
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '0',
          margin: '0',
          width: '100vw',
          height: '100vh'
        }}>
        <AppBar />
        <TabsRouter />
      </div>
    )
  }

  const renderContext = () => {
    return (
      <UserContext.Provider value={{ currUser, setCurrUser }}>
        <AppContext.Provider value={{ data, setData }}>
          {!isObjectEmptyOrNull(currUser) ? renderApp() : renderLogInPage()}
        </AppContext.Provider>
      </UserContext.Provider>
    )
  }

  return (
    <div>
      {
        !isObjectEmptyOrNull(msalInstance) &&
        <MsalProvider instance={msalInstance}>
          {
            renderContext()
          }
        </MsalProvider>

      }
      {
        isObjectEmptyOrNull(msalInstance) &&
        renderContext()
      }
    </div>
  )
}
export default App;
