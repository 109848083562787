import React, { useEffect, useMemo, useState } from 'react';
import { diffLines, formatLines } from 'unidiff';
import { parseDiff, Diff, Hunk } from 'react-diff-view';
import 'react-diff-view/style/index.css';
import './styles.css';
import tokenize from './tokenize';
import { Box, IconButton, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { CheckBox, KeyboardArrowDownRounded, KeyboardArrowRightRounded } from '@mui/icons-material';
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank';

const IGNORE_SPECIAL_CHARACTER_LIST = [' ', '|', '+', '-', '=']

const renderToken = (token, defaultRender, i) => {
    switch (token.type) {
        case 'edit':
            return (
                <span>
                    {(token.children && token.children.map((token, i) => {
                        const found = IGNORE_SPECIAL_CHARACTER_LIST.find(ignore => token?.value.includes(ignore))
                        if (found) {
                            const valueArr = token?.value.split('');
                            return valueArr.map((item, i) => {
                                const newToken = {
                                    'value': item,
                                    'type': IGNORE_SPECIAL_CHARACTER_LIST.includes(item) ? 'modify' : 'edit'
                                };
                                return defaultRender(newToken, i)
                            })
                        } else {
                            token = {
                                ...token,
                                'type': 'edit'
                            }
                            return defaultRender(token, i)
                        }
                    }))}
                </span>
            )
        default:
            return defaultRender(token, i);
    }
}

function DiffCheck(
    { oldText, 
      newText, 
      shouldDisplayRightSide, 
      leftName, 
      rightName, 
      tecaId, 
      selectedComparateeId,
      checkedComponentNames }) {    
    const [isExpanded, setIsExpanded] = useState(false);

    const [selectedTecaId, setSelectedTecaId] = useState('');
    const [tempCheckedComponents, setTempCheckedComponents] = useState({ ...checkedComponentNames });
    const handleCheckboxToggle = (checkboxKey) => {
        setTempCheckedComponents((prevState) => {
            const updatedState = { ...prevState };
            const currentArray = updatedState[selectedComparateeId] || [];
    
            if (currentArray.includes(checkboxKey)) {
                updatedState[selectedComparateeId] = currentArray.filter((key) => key !== checkboxKey);
            } else {
                updatedState[selectedComparateeId] = [...currentArray, checkboxKey];
            }
            return updatedState;
        });
    
        checkedComponentNames[selectedComparateeId] = checkedComponentNames[selectedComparateeId] || [];
    
        if (checkedComponentNames[selectedComparateeId].includes(checkboxKey)) {
            checkedComponentNames[selectedComparateeId] = checkedComponentNames[selectedComparateeId].filter(
                (key) => key !== checkboxKey
            );
        } else {
            checkedComponentNames[selectedComparateeId] = [
                ...checkedComponentNames[selectedComparateeId],
                checkboxKey,
            ];
        }
    };
    let selectedComponentName = checkedComponentNames[selectedComparateeId];

    useEffect(() => {
        setSelectedTecaId(tecaId && tecaId.length > 0 ? tecaId[0].id : '');
    }, [tecaId]);

    const handleChange = (event) => {
        setSelectedTecaId(event.target.value);
    };

    const diffText = formatLines(diffLines(oldText, newText), { context: 999 });
    const [diff] = parseDiff(diffText, { nearbySequences: 'zip' });
    const tokens = useMemo(() => tokenize(diff.hunks), [diff.hunks]);
    const leftSideBgColor = shouldDisplayRightSide ? '#fdeff0' : 'white'
    document.documentElement.style.setProperty('--diff-code-delete-background-color', leftSideBgColor);
    const renderTitle = (leftName, rightName) => {
        const normalClassName = 'diff-code diff-code-normal diff-code-comparator-only';
        const leftClassName = (diffText !== '' && shouldDisplayRightSide) ? '#f39ea2' : 'inherit';
        const rightClassName = diffText !== '' ? '#c0dc91' : 'inherit';
        const checkboxKey = leftName === '___' ? rightName : leftName;
        return (
            <div style={{ marginLeft: '-2.5rem' }}>
                <tr>
                    <th style={{ verticalAlign: 'middle' }}>
                    {selectedComponentName ? (
                        <IconButton
                            data-testid={`checkbox-button-${checkboxKey}`}
                            sx={{ bgcolor: 'inherit', '&:hover': { backgroundColor: 'inherit' } }}
                            onClick={() => handleCheckboxToggle(checkboxKey)}
                        >
                            {selectedComponentName.includes(checkboxKey) ? (
                                <CheckBox color="primary" />
                            ) : (
                                <CheckBoxOutlineBlank color="primary" />
                            )}
                        </IconButton>
                    ) 
                    : (
                        <span style={{ display: 'inline-block', width: '24px', height: '24px' }} />
                    )}
                    </th>
                    <th style={{ verticalAlign: 'middle' }}>
                        <IconButton
                            data-testid='expand-button-id'
                            sx={{ bgcolor: 'inherit', '&:hover': { backgroundColor: 'inherit' } }}
                            onClick={() => setIsExpanded(!isExpanded)}>
                            {
                                !isExpanded &&
                                <KeyboardArrowRightRounded color='primary' />
                            }
                            {
                                isExpanded &&
                                <KeyboardArrowDownRounded color='primary' />
                            }
                        </IconButton>
                    </th>
                    <th style={{ verticalAlign: 'middle' }}>
                        <table class='diff diff-split diff-code-comparator-only'>
                            <colgroup>
                                <col style={{ width: '50%' }}></col>
                                <col style={{ width: '50%' }}></col>
                            </colgroup>

                            <tbody class='diff-hunk'>

                            <td
                                data-testid='left-name-testid'
                                class={normalClassName}
                                style={{ verticalAlign: 'middle' }}
                            >
                                    <pre
                                        class='diff-code-edit'
                                        style={{
                                            fontSize: '1rem',
                                            marginLeft: '1.5rem',
                                            textAlign: 'middle',
                                            backgroundColor: leftClassName,
                                        }}
                                    >
                                        {leftName}
                                    </pre>
                            </td>
                            <td
                                data-testid='right-name-testid'
                                class={normalClassName}
                                style={{ verticalAlign: 'middle' }}
                            >
                                    <pre
                                        style={{
                                            fontSize: '1rem',
                                            marginLeft: '1.5rem',
                                            textAlign: 'middle',
                                            backgroundColor: rightClassName,
                                        }}>
                                        {rightName}
                                    </pre>
                            </td>
                            <td
                                data-testid='teca-id-testid'
                                style={{
                                    verticalAlign: 'middle',
                                    fontSize: '1rem',
                                    marginLeft: '1.5rem',
                                    textAlign: 'middle',
                                }}
                            >
                                {tecaId !== undefined && (
                                    <FormControl variant='standard' sx={{ m: 1, minWidth: 120 }}>
                                        <InputLabel id='teca-id-label'>TA ID</InputLabel>
                                        <Select
                                            labelId='teca-id-label'
                                            id='teca-id-select'
                                            value={selectedTecaId}
                                            onChange={handleChange}
                                            label='TA ID'
                                            sx={{
                                                width: '100%',
                                                maxWidth: '100%',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                '@media (min-width: 600px)': { maxWidth: '200px' },
                                            }}
                                        >
                                            {tecaId
                                                .filter(item => (
                                                        item.id !== undefined
                                                        && item.name !== undefined
                                                    )
                                                )
                                                .map(item => (
                                                    <MenuItem key={item.id} value={item.id}>
                                                        {`${item.id} - ${item.name}`}
                                                    </MenuItem>
                                                ))}
                                            {tecaId.includes('Not Found') && (
                                                <MenuItem value='Not Found'>Not Found</MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                )}
                            </td>
                            </tbody>
                        </table>
                    </th>
                </tr>

            </div>
        )
    }

    const renderDiffTable = () => {
        return (
            <Box>
                {
                    diffText === ''
                    &&
                    <div>
                        <table class='diff diff-split diff-code-comparator-only'>
                            <colgroup>
                                <col></col>
                                <col></col>
                            </colgroup>

                            <tbody class='diff-hunk'>
                                <td
                                    data-testid='oldTextData'
                                    class='diff-code diff-code-normal diff-code-comparator-only'
                                >
                                    <pre>{oldText}</pre>
                                </td>
                                <td
                                    data-testid='newTextData'
                                    class='diff-code diff-code-normal diff-code-comparator-only'
                                >
                                    <pre>{newText}</pre>
                                </td>
                            </tbody>
                        </table>
                    </div>
                }
                {
                    diffText !== ''
                    &&
                    <Box data-testid='diffView'>
                        <Diff
                            className='diff-code-comparator-only'
                            codeClassName='diff-code-comparator-only'
                            viewType='split'
                            diffType={'modify'}
                            hunks={diff.hunks}
                            tokens={tokens}
                            renderToken={renderToken}
                            gutterType='none'
                        >
                            {hunks => hunks.map(hunk => <Hunk key={hunk.content} hunk={hunk} />)}
                        </Diff>
                    </Box>
                }
            </Box>
        )
    }
    return (
        <Box>
            {
                renderTitle(leftName, rightName)
            }
            {
                isExpanded && renderDiffTable()
            }
        </Box>
    );
}

export default DiffCheck;
