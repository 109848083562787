import { CognitoUserPool, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import envConfig from '../../../environments/index';
import { Auth } from 'aws-amplify';
import { USER_POOL_GROUP_NAMES } from '../UserManagementPage/user';
import { isObjectEmptyOrNull } from '../../common/utils';

export function signIn(username, password) {
    const userPoolConfig = {
        UserPoolId: envConfig.USER_POOL_ID,
        ClientId: envConfig.USER_POOL_APP_CLIENT_ID
    }
    const UserPool = new CognitoUserPool(userPoolConfig)

    return new Promise((resolve, reject) => {
        const cognitoUser = new CognitoUser({
            Username: username,
            Pool: UserPool
        })
        const authDetails = new AuthenticationDetails({
            Username: username,
            Password: password
        })
        cognitoUser.authenticateUser(authDetails, {
            onSuccess: (result) => {
                resolve(result)
            },
            onFailure: (error) => {
                console.error(error)
                reject(error)
            },
            newPasswordRequired: (userAttr) => {
                let sessionAttr = userAttr
                delete sessionAttr.email_verified;
                delete sessionAttr.email;
                cognitoUser.completeNewPasswordChallenge(password, sessionAttr, {
                    onSuccess: (result) => {
                        resolve(result)
                    },
                    onFailure: (err) => {
                        console.log(err)
                        reject(err)
                    }
                })
            }
        })
    })
}

export function signOut() {
    try {
        Auth.signOut()
    } catch (err) {
        console.log('signOut-err: ' + err)
    }
}

export async function getCurrentSession() {
    try {
        const session = await Auth.currentSession();
        return session;
    } catch (err) {
        return console.log(err);
    }
}

export async function updateCurrentUser(session, currUser, setCurrUser) {
    if (!session) return {}
    const payload = session.getIdToken()?.payload || {}
    let user = {
        username: payload?.['cognito:username'],
        displayName: payload?.identities?.[0]?.userId || payload?.['cognito:username'],
        groups: payload?.['cognito:groups'] || [],
        email: payload?.email || '',
        emailVerified: payload?.email_verified,
        accessToken: session.getAccessToken(),
        refreshToken: session.getRefreshToken(),
        idToken: session.getIdToken(),
        expire: payload?.exp || 0
    }
    if (!hasPermission(user))
        user = {}
    if (JSON.stringify(user) !== JSON.stringify(currUser)){
        setCurrUser(user)
    }
    return user
}

export function hasPermission(user) {
    if (isObjectEmptyOrNull(user)) return false
    for (let group of user?.groups) {
        if (USER_POOL_GROUP_NAMES.includes(group)) {
            return true
        }
    }
    return false

}

export async function getAccessToken(currUser, setCurrUser) {
    if (!currUser) return null
    const currentTimestamp = Date.now() / 1000
    const expire = currUser?.expire
    if (currentTimestamp <= expire) {
        return currUser?.accessToken?.jwtToken
    } else {
        const newUser = await getCurrentSession().then(
            (session) => {
                if (session && session.getAccessToken() !== null) {
                    return updateCurrentUser(session, currUser, setCurrUser)
                }
                return null
            }
        )
        return newUser?.accessToken
    }
}

export async function getCredentials() {
    try {
        // Fetch credentials from Amplify
        const credentials = await Auth.currentCredentials();

        return credentials;
    } catch (error) {
        console.error('Error initializing S3 client:', error);
    }
    return null
}

